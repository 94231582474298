<template>
  <page :data="pageData" :is-child="_isChild" @refresh="refresh">
    <template #cmslefttype(vaddress-order-packageitem)="{ data: d }">
      <package-item
        :data="d.Data"
        @click="showDetail(d.Data)">
      </package-item>
    </template>

    <template #aftercmsleftcomponents>
      <!-- <package-detail-modal
        v-bind="modalProps('detail')"
        :data="detail"
        @close="closeDetail"
      >
      </package-detail-modal> -->
      <history-modal modal-key="detail"></history-modal>
    </template>
  </page>
</template>

<script>
import CMSPage from '../../base/CMSPage'
export default {
  extends: CMSPage,
  components: {
    PackageItem: () => import('@/components/vaddress/order/PackageItem'),
    HistoryModal: () => import('./HistoryModal')
  },
  data () {
    return {
      detail: null
    }
  },
  methods: {
    async showDetail (d) {
      this.openModal('detail', {
        url: '/user/orders/history?Oid=' + d.Oid
      })
      this.detail = await this.$baseApi.get('/user/orders/history?Oid=' + d.Oid)
    },
    closeDetail () {
      this.closeModal('detail')
      this.detail = null
    }
    // showDetail (d) {
    //   this.openModal('package', {
    //     url: '/user/orders/package?Oid=' + d.Oid
    //   })
    // }
  }
}
</script>
